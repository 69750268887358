.background-div {
  background-color: #292929;
  padding: 3rem 5rem 5rem 5rem;
  border-radius: 55px;
  width: 60%;
  position: relative;
  justify-content: center !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.container-sobre-title-custom {
  padding-bottom: 3rem;
}

.sobre-title-custom {
  color: white;

  position: relative;
  text-align: center;
  padding-bottom: 0.5rem;
}

.sobre-title-custom::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 35%;
  height: 1px;
  background: #06a759;
}

.background-div h2 {
  color: white;
}

h1 {
  margin: 0 0 20px 0;
}

.overflow-text {
  width: fit-content;
  background-color: #d9d9d9;
  padding: 2rem;
  white-space: break-spaces;
  width: 50%;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.overflow-text-descricao {
  font-weight: 400;
  padding-top: 0.5rem;
}

.container-sobre {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding-bottom: 10rem;
}

.custom-caixa-sobre {
  display: flex;
  width: 150%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-caixa-sobre img {
  width: 50%;
}

.container-overflows-texts {
  display: flex;
  flex-direction: column;
}

/* ajustes TRISTES na responsividade da caixa do projeto, zona TRISTE E PERIGOSA */

@media (max-width: 991px) {
  .custom-caixa-sobre {
    display: flex;
    flex-direction: column;
    box-shadow: none !important;
    justify-content: center;
    align-items: center;
    box-shadow: none;
  }

  .custom-caixa-sobre img {
    width: 80%;
  }

  .overflow-text {
    width: 80%;
  }
}

@media (max-width: 625px) {
  .custom-caixa-sobre img {
    width: 90%;
  }

  .overflow-text {
    width: 90%;
  }
}

@media (max-width: 585px) {
  .custom-caixa-sobre img {
    width: 100%;
  }

  .overflow-text {
    width: 100%;
  }
}

@media (max-width: 488px) {
  .custom-caixa-sobre {
    width: 100% !important;
  }

  .container-sobre {
    padding-bottom: 6rem;
  }

  .trocada {
    display: flex;
    flex-direction: column-reverse;
  }

  .sobre-title-custom {
    padding-bottom: 0.5rem;
  }
  .container-sobre-title-custom {
    padding-bottom: 2rem;
  }

  .background-div {
    position: unset;
    width: 100%;
    padding: 2rem;
    border-radius: 5px;
  }

  .overflow-text {
    width: 100%;
    border-radius: 0px 0px 50px 50px;
    text-align: center;
  }

  .custom-caixa-sobre img {
    width: 100%;
    border-radius: 50px 50px 0px 0px;
  }
}

@media (max-width: 1292px) {
  .custom-caixa-sobre {
    width: 170%;
  }
}

@media (max-width: 1173px) {
  .custom-caixa-sobre {
    width: 170%;
  }
}

@media (max-width: 1121px) {
  .custom-caixa-sobre {
    width: 185%;
  }
}

@media (max-width: 1122px) {
  .custom-caixa-sobre {
    width: 185%;
  }
}

@media (max-width: 1098px) {
  .custom-caixa-sobre {
    width: 190%;
  }
}

@media (max-width: 1081px) {
  .custom-caixa-sobre {
    width: 200%;
  }
}

@media (max-width: 1037px) {
  .custom-caixa-sobre {
    width: 210%;
  }
}

@media (max-width: 1000px) {
  .custom-caixa-sobre {
    width: 220%;
  }
}

/* animações */

.custom-caixa-sobre {
  display: flex;
  width: 150%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.animate-slide-in {
  opacity: 1;
  transform: translateX(0);
}
