.midia-container {
  background-color: #292929;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding-top: 4rem;
  padding-bottom: 6rem;
}

.titulo-midias-section {
  color: white;

  position: relative;
  text-align: center;
  padding-bottom: 0.5rem;
}

.titulo-midias-section::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 40%;
  height: 1px;
  background: #06a759;
}

.titulo-reportagens {
  color: white;
  max-width: 400px;
  padding-bottom: 0.5rem;
}

.data-reportagens {
  color: #b0b0b0;
}

.reportagem-completa-container {
  display: flex;
  gap: 4rem;
}

@media (max-width: 767px) {
  .reportagem-completa-container {
    flex-direction: column;
    gap: 1.5rem;
  }
  .ps-reportagens {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .titulo-reportagens {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .midia-container {
    border-radius: 5px 5px 0px 0px;
  }
}
