p {
  margin-bottom: 0 !important;
}

.custom-container-quero-ajudar {
  text-align: center;
  background-color: #292929;

  border-radius: 0px 0px 50px 50px;
  width: 60%;
  padding: 4rem 4rem 8rem 4rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-quero-ajudar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10rem;
}

.container-custom-quero-ajudar-title {
  background-color: #06a759 !important;
}

.custom-quero-ajudar-title {
  color: white;
  font-weight: 400 !important;
  text-align: center;
  margin-bottom: 0px;
  width: 100% !important;
  position: relative;
  text-align: center;
  padding-bottom: 0.8rem;
}

.custom-quero-ajudar-title::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 7%;
  height: 1px;
  background: black;
}

.custom-out-container-doe-pix {
  display: flex;
  justify-content: center;
}

.custom-container-doe-pix {
  background-color: #d9d9d9;
  padding: 0px 2rem 0px 2rem;
  display: flex;
  gap: 0.6rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-container-doe-pix h3 {
  font-size: 20px;
  font-weight: 700;
}
.custom-container-doe-pix h4 {
  font-size: 16px;
  font-weight: 500 !important;
}

.passo-a-passo-pix {
  max-width: 260px;
  text-align: left;
  font-weight: 500 !important;
}

.custom-pix-cnpj {
  background-color: #06a759;
  max-width: 439px;
  width: 100%;
  padding: 2rem;
  color: white;
  font-size: 1.3rem;
}

.outra-forma-text {
  color: white;
  font-weight: 500;
  font-size: 17px;
}

.fale-conosco-text {
  color: white;
  font-weight: 400;
  font-size: 15px;
  max-width: 500px;
}

.fale-conosco-text a {
  color: #06a759;
  font-weight: 600;
  text-decoration: none;
}
.cnpj-container {
  max-width: 439px;
  width: 100%;
}

.custom-icon-copy {
  border: none !important;
}

.copy-button {
  border: none;
  background: none;
  color: white;
  padding-left: 1rem;
}

.copy-message {
  font-size: 12px;
  color: #06a759;
}

@media (max-width: 767px) {
  .custom-container-quero-ajudar {
    width: 100%;
    border-radius: 0px 0px 20px 20px;
  }
}
