.footer-custom-container {
  background-color: #292929;
  color: white;
}

.footer-text-custom {
  font-size: 14px;
  font-weight: 200 !important;
  font-style: italic;
  text-align: center;
}

.custom-footer-texts {
  border-top: 0.5px solid rgb(54, 54, 54);
  padding: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.p-footer-custom {
  position: relative;
  font-size: 14px;
  text-align: center;
  padding-bottom: 0.5rem;
}

.p-footer-custom::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 5%;
  height: 1px;
  background: green;
}

.socials-footer-button-custom {
  text-decoration: none;
  display: flex;
  align-items: center;

  border: none;
  white-space: nowrap;

  border-radius: 60px !important;
  font-size: 0.9rem !important;
  padding: 0.6rem 1.4rem 0.6rem 1.4rem !important;
  color: white !important;
}

.radial-button {
  background: linear-gradient(
    180deg,
    rgba(255, 221, 85, 1) 0%,
    rgba(255, 84, 62, 1) 50%,
    rgba(200, 55, 171, 1) 100%
  );
}

.radial-button:hover {
  background: linear-gradient(
    180deg,
    rgb(228, 188, 31) 0%,
    rgb(214, 59, 39) 50%,
    rgb(170, 36, 143) 100%
  );
}

.radial-button img {
  padding-right: 0.5rem;
  max-width: 35px;
}

.footer-socials-buttons-custom {
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 2rem;
}

.facebook-custom-button {
  background-color: #1877f2;
}

.facebook-custom-button:hover {
  background-color: #196ad4;
}

.facebook-custom-button img {
  padding-right: 0.5rem;
  max-width: 35px;
}

.whats-custom-button {
  background-color: white;
  color: black !important;
}

.whats-custom-button:hover {
  background-color: rgb(231, 236, 250);
  color: black !important;
}

.whats-custom-button img {
  padding-right: 0.5rem;
  max-width: 35px;
}

@media (max-width: 767px) {
  .footer-socials-buttons-custom {
    justify-content: center;
    align-items: center;
  }

  .socials-footer-button-custom {
    width: 100%;
    justify-content: center;
  }

  .custom-footer-texts {
    flex-direction: column;
  }

  .footer-custom-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-socials-buttons-custom {
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 0.6rem;
  }

  .footer-socials-buttons-custom button {
    justify-content: center;
  }

  .p-footer-custom::after {
    width: 25%;
  }
}
