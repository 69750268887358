.img-custom-logo {
  max-width: 50%;
}

.navbar {
  background-color: white;
}

.title-page-custom {
  padding-left: 1rem;

  font-weight: 400 !important;
  font-size: 1.5rem;
}

.title-page-custom > span {
  font-weight: 700 !important;
}

.nav-link {
  color: black !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
}
.nav-link:hover {
  font-weight: 600 !important;
}

.container-fluid {
  --bs-gutter-x: 0rem !important;
}

.navbar-collapse {
  flex-grow: 0 !important;
}

/* sobrescrevendo estilo do tailwind */
.collapse {
  visibility: visible !important;
}

.send-button-custom {
  white-space: nowrap;

  background-color: #32970d !important;
  border-radius: 60px !important;
  font-size: 0.9rem !important;
  padding: 0.5rem 1.2rem 0.5rem 1.2rem !important;
  color: white !important;
}

.send-button-custom:hover {
  background-color: rgb(67, 168, 30) !important;
}

.navbar-nav {
  white-space: nowrap;
}

@media (max-width: 1060px) {
  .img-custom-logo {
    max-width: 50%;
  }

  .nav-item {
    text-align: center;
  }
  .nav-link {
    font-size: 15px !important;
  }

  .title-page-custom {
    font-size: 13px;
  }

  .navbar-toggler {
    margin-right: 0.5rem;
  }

  .personalized-a-title {
    width: min-content;
  }

  .send-button-custom {
    padding: 0.4rem 1.2rem 0.4rem 1.2rem !important;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 991px) {
  .navbar-collapse {
    display: flex;
    flex-direction: column;

    flex-grow: 0 !important;
  }
}
