.img-custom-carousel {
  height: 28rem;
}

.carousel-inner {
  border-radius: 25px !important;
  overflow: hidden !important;
}

@media (max-width: 991px) {
  .img-custom-carousel {
    height: 12rem;
  }
}
