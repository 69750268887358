.App {
  margin-top: 7rem;
}

@media (max-width: 1060px) {
  .App {
    margin-top: 3rem;
  }
}

@keyframes grow {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
